import { useValidation } from "vue3-form-validation";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { validMail } from "@/graphql/utils/send-mail";
import { ref } from "vue";
import { CONSTANTS } from "@/graphql/utils/utils";
import { useSendCodePin } from "@/graphql/user/send-code-pin";

export type ForgotPasswordSession = {
  email: string;
  resentTimes: number;
};

export const useForgotPassword = () => {
  const toast = useToast();
  const { t } = useI18n();
  const { push } = useRouter();

  const { loading, onDone, onError, sendCode } = useSendCodePin();
  onDone(({ data, errors }) => {
    if (errors) {
      toast.add({
        severity: "warn",
        summary: t("forgotPassword.sendCode"),
        detail: t(`forgotPassword.${errors[0].message}`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else if (data?.sendCodePin) {
      toast.add({
        severity: "success",
        summary: t("forgotPassword.sendCode"),
        detail: t("forgotPassword.success"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      sessionStorage.setItem(
        CONSTANTS.forgotPassword,
        JSON.stringify(data.sendCodePin)
      );
      session.value = {
        email: data.sendCodePin.email,
        resentTimes: data.sendCodePin.resentTimes,
      };
      setTimeout(() => {
        void push(
          `${localStorage.getItem(CONSTANTS.appSource)}/reset-password`
        );
      }, 2000);
    } else {
      toast.add({
        severity: "error",
        summary: t("forgotPassword.sendCode"),
        detail: t("sendMail.error"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });
  onError((err) => {
    toast.add({
      severity: "warn",
      summary: t("forgotPassword.sendCode"),
      detail: t("networkError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });
  const session = ref<ForgotPasswordSession>(
    JSON.parse(sessionStorage.getItem(CONSTANTS.forgotPassword))
  );
  const { validateFields, hasError, form } = useValidation({
    email: {
      $value: session.value?.email || "",
      $rules: [(m: string) => !validMail(m) && ""],
    },
  });

  function submit() {
    validateFields().then((input) => {
      void sendCode({
        input: {
          email: input.email,
          reason: "resetPasswordCode",
        },
      });
    });
  }
  return {
    validateFields,
    hasError,
    form,
    loading,
    submit,
    session,
  };
};
