
import { defineComponent } from "vue";
import { useForgotPassword } from "@/graphql/user/forgot-password";

export default defineComponent({
  name: "ForgotPassword",
  setup() {
    return {
      ...useForgotPassword(),
    };
  },
});
